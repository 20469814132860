/* eslint-disable react/function-component-definition */
import React, { useEffect, useContext, useState, Suspense } from "react";
import { SpinnerDotted } from "spinners-react";
import axios from "axios";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import styles from "./index.module.scss";
import CustomTable from "../../components/CustomTable/CustomTable";
import { REACT_APP_API_FETCH_VIDEO } from "../../redux/apiConstants";
import { TableContext } from "../../contexts/tableContext";
import envValues from "../../enviornment";
import { getCookies } from "../../hooks/useCookies";

const VideoIndex = React.memo(() => {
  const { setDashboardHeader, dashboardStyle, setCurrentContest } =
    useContext(TableContext);
  const token = `Bearer ${getCookies("Token")}`;
  setDashboardHeader("Neutral Videos");
  let statusCode;
  const [contest, setContest] = useState();
  const [loading, setLoading] = useState(true);
  const [contestNumber, setContestNumber] = useState(1);
  const [contestName, setContestName] = useState("");
  const [timeRemaining, setTimeRemaining] = useState(360000);
  const [curtainClose, setCurtainClose] = useState(360000);

  const getCurrentContest = async () => {
    const url = `${envValues.REACT_APP_API_ENDPOINT}/contest/get/current-contest`;
    axios
      .get(url, {
        method: "GET",
        headers: { Authorization: token },
      })
      .then((resp) => {
        statusCode = resp.status;
        return resp;
      })
      .then((res) => {
        if (statusCode === 200) {
          setContest(res.data.data.result);
          setCurrentContest(res.data.data.result);
          setContestNumber(res.data.data.result.contestNumber);
          setContestName(res.data.data.result.contestName);
          setTimeRemaining(res.data.data.timeRemaining);
          setCurtainClose(res.data.data.timeRemainingCurtain);
        }
      })
      .finally(() => {
        setLoading(false);
      })
      .catch((err) => err);
  };

  const formatTime = (milliseconds) => {
    if (milliseconds <= 0) {
      return {
        hours: "00",
        minutes: "00",
        seconds: "00",
      };
    }
    const hours = Math.floor(milliseconds / (1000 * 60 * 60));
    const minutes = Math.floor((milliseconds % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((milliseconds % (1000 * 60)) / 1000);
    return {
      hours: String(hours).padStart(2, "0"),
      minutes: String(minutes).padStart(2, "0"),
      seconds: String(seconds).padStart(2, "0"),
    };
  };

  const formattedTimeTotal = formatTime(timeRemaining);
  const formattedTimeCurtain = formatTime(curtainClose);

  useEffect(() => {
    window.scrollTo(0, 0);
    getCurrentContest();
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (timeRemaining > 0) {
        setTimeRemaining((prevTime) => prevTime - 1000);
      } else {
        clearInterval(intervalId);
      }
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [timeRemaining]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (curtainClose > 0) {
        setCurtainClose((prevTime) => prevTime - 1000);
      } else {
        clearInterval(intervalId);
      }
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [curtainClose]);

  return (
    <div className={styles.indexdiv}>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "10vh",
          }}
        >
          <SpinnerDotted style={{ color: "#39979d" }} />
        </div>
      ) : (
        <div className={styles[dashboardStyle]} id={styles.dashboardcont2}>
          {!contest ? (
            <p>Currently no Contest is running</p>
          ) : (
            <div className="row analyst_dashboard">
              <div className="col-xl-3 col-lg-6 col-md-12 col-sm-12">
                <div className="row">
                  <div className="col-md-12">
                    <div>
                      <ul className={styles.cardsection}>
                        <Suspense
                          fallback={
                            <div>
                              <SpinnerDotted
                                style={{
                                  marginTop: "20px",
                                  marginLeft: "120px",
                                  color: "#39979d",
                                }}
                              />
                            </div>
                          }
                        >
                          <div className={styles.timeRemaining}>
                            <h1>Season Number:</h1>
                            <div>{contestNumber}</div>
                          </div>
                        </Suspense>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-6 col-md-12 col-sm-12">
                <div className="row">
                  <div className="col-md-12">
                    <div>
                      <ul className={styles.cardsection}>
                        <Suspense
                          fallback={
                            <div>
                              <SpinnerDotted
                                style={{
                                  marginTop: "20px",
                                  marginLeft: "120px",
                                  color: "#39979d",
                                }}
                              />
                            </div>
                          }
                        >
                          <div className={styles.timeRemaining}>
                            <h1>Current Contest:</h1>
                            <div>{contestName}</div>
                          </div>
                        </Suspense>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-6 col-md-12 col-sm-12">
                <div className="row">
                  <div className="col-md-12">
                    <div>
                      <ul className={styles.cardsection}>
                        <Suspense
                          fallback={
                            <div>
                              <SpinnerDotted
                                style={{
                                  marginTop: "20px",
                                  marginLeft: "120px",
                                  color: "#39979d",
                                }}
                              />
                            </div>
                          }
                        >
                          <div className={styles.timeRemaining}>
                            <h1>Total Time Remaining: </h1>
                            <div>
                              <span>{formattedTimeTotal.hours}h</span>:
                              <span>{formattedTimeTotal.minutes}m</span>:
                              <span>{formattedTimeTotal.seconds}s</span>
                            </div>
                          </div>
                        </Suspense>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-6 col-md-12 col-sm-12">
                <div className="row">
                  <div className="col-md-12">
                    <div>
                      <ul className={styles.cardsection}>
                        <Suspense
                          fallback={
                            <div>
                              <SpinnerDotted
                                style={{
                                  marginTop: "20px",
                                  marginLeft: "120px",
                                  color: "#39979d",
                                }}
                              />
                            </div>
                          }
                        >
                          <div className={styles.timeRemaining}>
                            <h1>Time Remaining for curtain close: </h1>
                            <div>
                              <span>{formattedTimeCurtain.hours}h</span>:
                              <span>{formattedTimeCurtain.minutes}m</span>:
                              <span>{formattedTimeCurtain.seconds}s</span>
                            </div>
                          </div>
                        </Suspense>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      <CustomTable
        urlParam={REACT_APP_API_FETCH_VIDEO}
        viewVideo
        section="video"
        // toggleButton={{ show: true, field: "active" }}
        deleteCondition={{ checkCondition: false, apiUrl: "www.google.com" }}
      />
    </div>
  );
});
export default VideoIndex;
