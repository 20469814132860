import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { Label } from "reactstrap";
import { SpinnerDotted } from "spinners-react";
import { ToastContainer, toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/fontawesome-free-solid";
import TextInput from "../../components/TextField/TextField";
// import SelectBox from "../../components/SelectBox/SelectBox";
import { Entry } from "../../redux/entrySlice";
// import DatePickerComponent from "../../components/DatePicker/DatePicker";
import { TableContext } from "../../contexts/tableContext";
import styles from "./editContest.module.scss";
import DateAndTimePickerComponent from "../../components/DateAndTimePicker/DateAndTimePicker";
import NumberInput from "../../components/NumberBox/NumberBox";

function ContestEdit() {
  let contestId;
  const [submiting, setSubmitting] = useState(false);
  const { dashboardStyle } = useContext(TableContext);
  // const { setDashboardHeader } = useContext(TableContext);
  const { bodyStyle } = useContext(TableContext);
  const { formthemeStyle } = useContext(TableContext);
  const { setDashboardHeader } = useContext(TableContext);
  const { errormsgStyle } = useContext(TableContext);
  const params = useParams();
  const [contest, setContest] = useState([]);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  useEffect(() => {
    window.scrollTo(0, 0);
    setDashboardHeader("Edit Competition");
    contestId = params.contestId;

    const actionData = {};
    actionData.actionUrl = `contest/${contestId}`;
    actionData.actionMethod = "get";
    dispatch(Entry(actionData)).then((resp) => {
      setContest(resp.payload.data.contest);
      reset(resp.payload.data.contest);
    });
  }, []);
  // eslint-disable-next-line no-unused-vars
  function onSubmitdata(data) {
    if (data.contestName.trim().length === 0) {
      document.getElementById("contestName").focus();
      setError("Please enter contest name");
      setSubmitting(false);
      return;
    }
    if (Number(data.participantCount) <= 0) {
      document.getElementById("participantCount").focus();
      setError("Please enter valid participant count");
      setSubmitting(false);
      return;
    }
    if (Number(data.finalistCount) <= 0) {
      document.getElementById("finalistCount").focus();
      setError("Please enter valid finalist count");
      setSubmitting(false);
      return;
    }
    if (Number(data.participantCount) < Number(data.finalistCount)) {
      document.getElementById("finalistCount").focus();
      setError("Finalist count cannot be greater than participant count");
      setSubmitting(false);
      return;
    }
    setSubmitting(true);
    setError(null);
    const apiData = {
      contestName: data.contestName,
      startDate: data.startDate,
      endDate: data.endDate,
      curtainClose: data.curtainClose,
      participantCount: data.participantCount,
      finalistCount: data.finalistCount,
    };
    data.actionUrl = `contest/${params.contestId}`;
    data.actionMethod = "patch";
    data.apiData = apiData;
    dispatch(Entry(data)).then((resp) => {
      setSubmitting(false);
      if (resp.payload.code === 200) {
        navigate(-1);
        toast.success("Details updated successfully");
        // eslint-disable-next-line max-len
      } else if (
        resp.payload.code === 401 ||
        resp.payload.code === 400 ||
        resp.payload.code === 406
      ) {
        setError(resp.payload.message);
      } else {
        navigate(-1);
      }
    });
  }
  return (
    <div className={styles[bodyStyle]}>
      <div
        className={styles[dashboardStyle]}
        style={{ minHeight: "100vh" }}
        id={styles.dashboardcont2}
      >
        <div className={styles[formthemeStyle]} id={styles.addform}>
          <FontAwesomeIcon
            icon={faArrowLeft}
            value="Back"
            onClick={() => {
              navigate(-1);
            }}
            className={styles.arrowback}
          />
          <div className="container-fluid mt-5 edituserform">
            {contest.length !== 0 ? (
              <form onSubmit={handleSubmit(onSubmitdata)}>
                <div className="row">
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    {contest && (
                      <TextInput
                        className={styles.inputbox}
                        classNamedark={styles.inputbox1}
                        labelstyle={styles.formlabel}
                        label="Competition Name"
                        fieldName="contestName"
                        placeHolder="Enter Competition Name"
                        register={register}
                        errors={errors}
                        isRequired
                        mandatory
                      />
                    )}
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    <NumberInput
                      className={styles.inputbox}
                      classNamedark={styles.inputbox1}
                      labelstyle={styles.formlabel}
                      label="Number of Participants"
                      fieldName="participantCount"
                      placeHolder="Enter the Number of Participants"
                      register={register}
                      errors={errors}
                      isRequired
                      mandatory
                      maximLength={3}
                      id="participantCount"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6 col-md-12 col-sm-12 mb-3">
                    <Label className={styles.formlabel}>Start Date</Label>
                    <span style={{ color: "red", marginLeft: "3px" }}>*</span>
                    <DateAndTimePickerComponent
                      className={styles.dateinputbox}
                      classNamedark={styles.dateinputbox1}
                      control={control}
                      name="startDate"
                      isRequired
                      errors={errors}
                      label="Start Date"
                      minDate={new Date()}
                    />
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12 mb-3">
                    <Label className={styles.formlabel}>End Date</Label>
                    <span style={{ color: "red", marginLeft: "3px" }}>*</span>
                    <DateAndTimePickerComponent
                      className={styles.dateinputbox}
                      classNamedark={styles.dateinputbox1}
                      control={control}
                      name="endDate"
                      isRequired
                      errors={errors}
                      label="End Date"
                      minDate={new Date()}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6 col-md-12 col-sm-12 mb-3">
                    <Label className={styles.formlabel}>
                      Curtain Close Date
                    </Label>
                    <span style={{ color: "red", marginLeft: "3px" }}>*</span>
                    <DateAndTimePickerComponent
                      className={styles.dateinputbox}
                      classNamedark={styles.dateinputbox1}
                      control={control}
                      name="curtainClose"
                      isRequired
                      errors={errors}
                      label="Curtain Close Date"
                      minDate={new Date()}
                    />
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12 mb-3">
                    <NumberInput
                      className={styles.inputbox}
                      classNamedark={styles.inputbox1}
                      labelstyle={styles.formlabel}
                      label="Number of Finalists"
                      fieldName="finalistCount"
                      placeHolder="Enter the Number of Finalists"
                      register={register}
                      errors={errors}
                      isRequired
                      mandatory
                      maximLength={3}
                      id="finalistCount"
                    />
                  </div>
                </div>
                <input
                  className={styles.formbtn}
                  type="submit"
                  disabled={submiting}
                  value={submiting ? "Please wait.." : "Update"}
                  onClick={() => {
                    setError(false);
                  }}
                />
                {error && <h6 className={styles[errormsgStyle]}>{error}</h6>}
              </form>
            ) : (
              <div
                style={{
                  textAlign: "center",
                  marginTop: "130px",
                  color: "#39979d",
                }}
              >
                <SpinnerDotted />
              </div>
            )}
            <br />
            <ToastContainer autoClose={2000} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContestEdit;
