/* eslint-disable react/button-has-type */
/* eslint-disable react/function-component-definition */
import React, { useEffect, useContext } from "react";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import styles from "./index.module.scss";
import CustomTable from "../../components/CustomTable/CustomTable";
import { TableContext } from "../../contexts/tableContext";
import envValues from "../../enviornment";

const ViewBuyInterest = React.memo(() => {
  const params = useParams();
  const navigate = useNavigate();
  const { setDashboardHeader } = useContext(TableContext);
  setDashboardHeader("Video Buy Request List");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const handleClick = () => {
    navigate(-1);
  };
  return (
    <div className={styles.indexdiv}>
      <FontAwesomeIcon
        icon={faArrowLeft}
        value="Back"
        onClick={handleClick}
        className={styles.arrowback}
      />
      <button onClick={handleClick} className={styles.backButton}>
        Back
      </button>
      <CustomTable
        urlParam={`${envValues.REACT_APP_API_ENDPOINT}/api/video/buy-interest/${params.videoId}?`}
        // viewPopUp
        section="buy-interest"
        // toggleButton={{ show: true, field: "active" }}
        deleteCondition={{ checkCondition: false, apiUrl: "www.google.com" }}
      />
    </div>
  );
});
export default ViewBuyInterest;
