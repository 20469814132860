/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/forbid-prop-types */
import React from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import DateAndTimePicker from "react-datetime-picker";
import styles from "./DateAndTimePicker.module.scss";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";

function DateAndTimePickerComponent({
  control,
  name,
  isRequired,
  errors,
  className,
  classNamedark,
  label,
  minDate, // Add minDate as a prop
}) {
  const appliedClassName =
    localStorage.getItem("theme") === "dark"
      ? classNamedark || className
      : className;

  return (
    <>
      <Controller
        control={control}
        name={name}
        rules={{
          required: {
            value: isRequired,
            message: `${label} is required`,
          },
          validate: (value) => {
            // Custom validator for minDate
            if (minDate && new Date(value) < minDate) {
              return `Please ensure the time selected is later than now.`;
            }
            return true;
          },
        }}
        render={({ field }) => (
          <div>
            <DateAndTimePicker
              className={appliedClassName}
              id={styles.datepicker}
              onChange={(date) => field.onChange(date)}
              value={
                typeof field.value === "string"
                  ? new Date(field.value)
                  : field.value
              }
              disableClock
              format="d-MM-yyyy h:mm a"
              amPmAriaLabel="AM/PM"
              minDate={minDate} // Pass minDate to DatePicker
            />
          </div>
        )}
      />
      {errors[name] && (
        <p style={{ color: "red" }} className="error-message">
          {errors[name].message}
        </p>
      )}
    </>
  );
}

DateAndTimePickerComponent.propTypes = {
  control: PropTypes.oneOfType([PropTypes.object]).isRequired,
  name: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  errors: PropTypes.object.isRequired,
  className: PropTypes.string,
  classNamedark: PropTypes.string,
  label: PropTypes.string,
  minDate: PropTypes.instanceOf(Date), // PropType for minDate
};

DateAndTimePickerComponent.defaultProps = {
  isRequired: false,
  classNamedark: null,
  className: "default-input-class",
  label: "Date",
  minDate: new Date(), // Default to today's date
};

export default DateAndTimePickerComponent;
