/* eslint-disable react/function-component-definition */
import React, { useState, useEffect, useContext } from "react";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import axios from "axios";
import { useNavigate } from "react-router-dom";
import styles from "./index.module.scss";
import CustomTable from "../../components/CustomTable/CustomTable";
import { REACT_APP_API_FETCH_CURRENT_CONTEST } from "../../redux/apiConstants";
import { TableContext } from "../../contexts/tableContext";
import envValues from "../../enviornment";
import { getCookies } from "../../hooks/useCookies";

const CurrentContestIndex = React.memo(() => {
  const { setDashboardHeader } = useContext(TableContext);
  setDashboardHeader("Current Contest");
  const [timeRemaining, setTimeRemaining] = useState("initial state");
  let statusCode;
  const token = `Bearer ${getCookies("Token")}`;

  const getCurrentContest = async () => {
    const url = `${envValues.REACT_APP_API_ENDPOINT}/contest/get/current-contest`;
    axios
      .get(url, {
        method: "GET",
        headers: { Authorization: token },
      })
      .then((resp) => {
        statusCode = resp.status;
        return resp;
      })
      .then((res) => {
        if (statusCode === 200) {
          if (res.data.data.timeRemaining > 0)
            setTimeRemaining(res.data.data.timeRemaining);
        }
      })
      .finally(() => {})
      .catch((err) => err);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getCurrentContest();
  }, []);
  const navigate = useNavigate();
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (timeRemaining !== "initial state" && timeRemaining > 0) {
        setTimeRemaining((prevTime) => prevTime - 1000);
      } else if (timeRemaining !== "initial state" && timeRemaining <= 0) {
        navigate("/past-contest");
        clearInterval(intervalId);
      } else {
        clearInterval(intervalId);
      }
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [timeRemaining]);
  return (
    <div className={styles.indexdiv}>
      <CustomTable
        urlParam={REACT_APP_API_FETCH_CURRENT_CONTEST}
        // viewPopUp
        section="current-contest"
        // toggleButton={{ show: true, field: "active" }}
        deleteCondition={{ checkCondition: false, apiUrl: "www.google.com" }}
      />
    </div>
  );
});
export default CurrentContestIndex;
