/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-nested-ternary */
/* eslint-disable global-require */
import axios from "axios";
// import Avatar from 'react-avatar';
import { faPlayCircle, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useState, useContext } from "react";
import { Col, Row, Container, Image, Button } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
// import { confirmAlert } from "react-confirm-alert";
import { SpinnerDotted } from "spinners-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { toast } from "react-toastify";
import { faArrowLeft } from "@fortawesome/fontawesome-free-solid";
import { Entry } from "../../redux/entrySlice";
// import { useDispatch } from "react-redux";
import { getCookies } from "../../hooks/useCookies";
import { TableContext } from "../../contexts/tableContext";
import styles from "./viewVideo.module.scss";
import envValues from "../../enviornment";
import useToggle from "../../hooks/useToggle";
import { DateFormatter } from "../../utilityFunctions/utilsFunctions";
import VideoPopUp from "../../components/VideoViewPopUp/videoView";
import ViewUsersPopUp from "../../components/ViewUsersPopUp/ViewUsersPopup";
// import { Entry } from "../../redux/entrySlice";

function ViewApprovedVideo() {
  const { dashboardStyle } = useContext(TableContext);
  const { setDashboardHeader } = useContext(TableContext);
  const { bodyStyle } = useContext(TableContext);
  const { formthemeStyle } = useContext(TableContext);
  const params = useParams();
  // const dispatch = useDispatch();
  const [showVideoPopUp, setShowVideoPopUp] = useToggle();
  const [showUsersPopUp, setShowUsersPopUp] = useToggle();
  // const [showButton, setShowButton] = useState(true);
  const { viewformStyle } = useContext(TableContext);
  let videoId;
  const token = `Bearer ${getCookies("Token")}`;
  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState(false);
  let statusCode;
  const dispatch = useDispatch();
  const getContestDetails = async () => {
    setLoading(true);
    axios
      .get(`${envValues.REACT_APP_API_ENDPOINT}/api/video/${videoId}`, {
        method: "GET",
        headers: { Authorization: token },
      })
      .then((resp) => {
        statusCode = resp.status;
        return resp.data;
      })
      .then((res) => {
        if (statusCode === 200) {
          setDetails(res.data.VideoUpload);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        return err;
      });
  };
  const handlePlay = () => {
    setShowVideoPopUp(true);
  };
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
    videoId = params.videoId;
    getContestDetails();
    setDashboardHeader("Video Details");
  }, []);

  // const addToFinalist = (data) => {
  //   confirmAlert({
  //     title: "",
  //     message: "Are you sure you want to add this video to finalist?",
  //     buttons: [
  //       {
  //         label: "Yes",
  //         onClick: () => {
  //           const actionData = {};
  //           actionData.actionUrl = `api/video/selectfinalist/${data.id}`;
  //           actionData.actionMethod = "patch";
  //           dispatch(Entry(actionData)).then(() => {
  //             toast.success("Added to Finalist");
  //             navigate(-1);
  //           });
  //         },
  //       },
  //       {
  //         label: "No",
  //         // onClick: () => alert("Click No")
  //       },
  //     ],
  //   });
  // };
  const capitalizeFirstLetter = (string) => {
    if (!string) return string;
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  const actionReject = (dataParams) => {
    confirmAlert({
      title: "",
      message:
        "Are you sure you want to Reject this approved video? This will move it to the Rejected Videos tab.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            const actionData = {};
            actionData.actionUrl = `api/video/reject/${dataParams.id}`;
            actionData.actionMethod = "patch";
            dispatch(Entry(actionData)).then(() => {
              toast.success("Rejected Successfully");
              navigate(-1);
            });
          },
        },
        {
          label: "No",
          // onClick: () => alert("Click No")
        },
      ],
    });
  };
  return (
    <div className={styles[bodyStyle]}>
      <div
        className={styles[dashboardStyle]}
        style={{ minHeight: "100vh" }}
        id={styles.dashboardcont2}
      >
        <div className={styles[formthemeStyle]} id={styles.addform}>
          {loading ? (
            <SpinnerDotted
              style={{
                color: "#39979d",
                left: "50%",
                position: "relative",
                textAlign: "center",
                top: "50%",
              }}
            />
          ) : (
            <Container fluid>
              <FontAwesomeIcon
                icon={faArrowLeft}
                value="Back"
                onClick={() => {
                  navigate(-1);
                }}
                className={styles.arrowback}
              />
              <Row className="mt-3">
                <Col lg={12} md={12} sm={12}>
                  <div className={styles.detaildiv}>
                    <div className={styles.thumbnailContainer}>
                      <Image
                        src={details.thumbnail}
                        alt={details.description}
                        className={styles.profilepic}
                      />
                      <FontAwesomeIcon
                        icon={faPlayCircle}
                        className={styles.playButton}
                        onClick={handlePlay}
                      />
                    </div>
                    <Row>
                      <Col lg={6}>
                        <div
                          className={styles[viewformStyle]}
                          id={styles.empdiv}
                        >
                          <span className={styles.title}>User Name</span>
                          <p className={styles.empname}>
                            {details.userName === undefined || ""
                              ? "N/A"
                              : details?.userName}
                          </p>
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div
                          className={styles[viewformStyle]}
                          id={styles.empdiv}
                        >
                          <span className={styles.title}>Title</span>
                          <p className={styles.empname}>
                            {details.description === undefined || ""
                              ? "N/A"
                              : details?.description}
                          </p>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6}>
                        <div
                          className={styles[viewformStyle]}
                          id={styles.empdiv}
                        >
                          <span className={styles.title}>Duration</span>
                          <p className={styles.empname}>
                            {details.duration === undefined || ""
                              ? "N/A"
                              : details?.duration}
                          </p>
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div
                          className={styles[viewformStyle]}
                          id={styles.empdiv}
                        >
                          <span className={styles.title}>Uploaded Date</span>
                          <p className={styles.empname}>
                            {details.uploadedTime
                              ? DateFormatter(
                                  details.uploadedTime,
                                  "toDateTime"
                                )
                              : "N/A"}
                          </p>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6}>
                        <div
                          className={styles[viewformStyle]}
                          id={styles.empdiv}
                        >
                          <span className={styles.title}>Status</span>
                          <p className={styles.empname}>
                            {details.status === undefined || ""
                              ? "N/A"
                              : capitalizeFirstLetter(details.status)}
                          </p>
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div
                          className={styles[viewformStyle]}
                          id={styles.empdiv}
                        >
                          <span className={styles.title}>Approved Date</span>
                          <p className={styles.empname}>
                            {details.approvedTime
                              ? DateFormatter(
                                  details.approvedTime,
                                  "toDateTime"
                                )
                              : "N/A"}
                          </p>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6}>
                        <div
                          className={styles[viewformStyle]}
                          id={styles.empdiv}
                        >
                          <span className={styles.title}>Video Rank</span>
                          <p className={styles.empname}>
                            {details.videoRank === undefined || ""
                              ? "0"
                              : details?.videoRank}
                          </p>
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div
                          className={styles[viewformStyle]}
                          id={styles.empdiv}
                        >
                          <span className={styles.title}>View Count</span>
                          <p className={styles.empname}>
                            {details.viewCount === undefined || ""
                              ? "0"
                              : details?.viewCount}
                          </p>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={4}>
                        <div
                          className={`${styles[viewformStyle]} ${styles.case_chck}`}
                          id={styles.empdiv}
                          style={{
                            cursor: "pointer",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                          onClick={() => {
                            navigate(`/upvote/viewdetails/${details.id}`);
                          }}
                        >
                          <div>
                            <span className={styles.title}>Upvote Count</span>
                            <p className={styles.empname}>
                              {details.upvoteCount === undefined || ""
                                ? "0"
                                : details?.upvoteCount}
                            </p>
                          </div>
                          <FontAwesomeIcon
                            icon={faArrowRight}
                            className={styles.arrowIcon}
                          />
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div
                          className={`${styles[viewformStyle]} ${styles.case_chck}`}
                          style={{
                            cursor: "pointer",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                          id={styles.empdiv}
                          onClick={() => {
                            navigate(`/buy-interest/viewdetails/${details.id}`);
                          }}
                        >
                          <div>
                            <span className={styles.title}>Buy Interest</span>
                            <p className={styles.empname}>
                              {details.buyIntrestCount === undefined || ""
                                ? "0"
                                : details?.buyIntrestCount}
                            </p>
                          </div>
                          <FontAwesomeIcon
                            icon={faArrowRight}
                            className={styles.arrowIcon}
                          />
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div
                          className={`${styles[viewformStyle]} ${styles.case_chck}`}
                          style={{
                            cursor: "pointer",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                          id={styles.empdiv}
                          onClick={() => {
                            navigate(`/connect/viewdetails/${details.id}`);
                          }}
                        >
                          <div>
                            <span className={styles.title}>
                              Connected Users Count
                            </span>
                            <p className={styles.empname}>
                              {details.connectedUsersCount === undefined || ""
                                ? "0"
                                : details?.connectedUsersCount}
                            </p>
                          </div>
                          <FontAwesomeIcon
                            icon={faArrowRight}
                            className={styles.arrowIcon}
                          />
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      {details.aiSummary &&
                        Object.keys(details.aiSummary).length > 0 && (
                          <Col lg={6}>
                            <div
                              className={styles[viewformStyle]}
                              id={styles.empdiv}
                            >
                              <span className={styles.title}>AI Summary</span>
                              {typeof details.aiSummary === "object" ? (
                                Object.entries(details.aiSummary || {}).map(
                                  ([key, value]) => (
                                    <p key={key} className={styles.empname}>
                                      <strong>
                                        {key.replace(/([A-Z])/g, " $1")}:{" "}
                                      </strong>{" "}
                                      {value || "N/A"}
                                    </p>
                                  )
                                )
                              ) : (
                                <p className={styles.empname}>
                                  {details.aiSummary || "N/A"}
                                </p>
                              )}
                            </div>
                          </Col>
                        )}
                      {details.aiInsights &&
                        Object.keys(details.aiInsights).length > 0 && (
                          <Col lg={6}>
                            <div
                              className={styles[viewformStyle]}
                              id={styles.empdiv}
                            >
                              <span className={styles.title}>AI Insights</span>
                              {typeof details.aiInsights === "object" ? (
                                Object.entries(details.aiInsights || {}).map(
                                  ([key, value]) => (
                                    <p key={key} className={styles.empname}>
                                      <strong>
                                        {key.replace(/([A-Z])/g, " $1")}:{" "}
                                      </strong>{" "}
                                      {value || "N/A"}
                                    </p>
                                  )
                                )
                              ) : (
                                <p className={styles.empname}>
                                  {details.aiInsights || "N/A"}
                                </p>
                              )}
                            </div>
                          </Col>
                        )}
                    </Row>

                    <Row>
                      {details.contentRating &&
                        Object.keys(details.contentRating).length > 0 && (
                          <Col lg={6}>
                            <div
                              className={styles[viewformStyle]}
                              id={styles.empdiv}
                            >
                              <span className={styles.title}>
                                Content Rating
                              </span>
                              {typeof details.contentRating === "object" ? (
                                Object.entries(details.contentRating || {}).map(
                                  ([key, value]) => (
                                    <p key={key} className={styles.empname}>
                                      <strong>
                                        {key.replace(/([A-Z])/g, " $1")}:{" "}
                                      </strong>{" "}
                                      {value || "N/A"}
                                    </p>
                                  )
                                )
                              ) : (
                                <p className={styles.empname}>
                                  {details.contentRating || "N/A"}
                                </p>
                              )}
                            </div>
                          </Col>
                        )}
                      {details.aiRecommendation && (
                        <Col lg={6}>
                          <div
                            className={styles[viewformStyle]}
                            id={styles.empdiv}
                          >
                            <span className={styles.title}>
                              AI Recommendation
                            </span>
                            <p className={styles.empname}>
                              {details?.aiRecommendation}
                            </p>
                          </div>
                        </Col>
                      )}
                    </Row>

                    <Row className="justify-content-center mt-4">
                      <Col lg={12} className="text-center">
                        <Button
                          variant="danger"
                          onClick={() => actionReject(details)} // Reject Button
                        >
                          Reject
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Container>
          )}
        </div>
      </div>
      {showVideoPopUp ? (
        <VideoPopUp dataId={details.id} handleClose={setShowVideoPopUp} />
      ) : null}
      {showUsersPopUp ? (
        <ViewUsersPopUp dataId={details.id} handleClose={setShowUsersPopUp} />
      ) : null}
    </div>
  );
}

export default ViewApprovedVideo;
