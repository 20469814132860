/* eslint-disable no-unused-vars */
import React, { useContext, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import ReactTooltip from "react-tooltip";
import PropTypes from "prop-types";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";
import styles from "./ApproveReject.module.scss";
import { Entry } from "../../redux/entrySlice";
import { TableContext } from "../../contexts/tableContext";

function ApproveReject({ data, section, deleteCondition, pageNum }) {
  const {
    actionbtnStyle,
    setPageNumView,
    setApproved,
    setRejected,
    currentContest,
  } = useContext(TableContext);
  const dispatch = useDispatch();

  const actionApprove = (params) => {
    setPageNumView(pageNum);
    if (section === "video") {
      if (!currentContest) {
        confirmAlert({
          title: "No Active Contest..!",
          message:
            "Do you still want to approve this video? Approved videos will be queued for the next contest.",
          buttons: [
            {
              label: "Yes",
              onClick: () => {
                const actionData = {};
                actionData.actionUrl = `api/video/approve/${params.id}`;
                actionData.actionMethod = "patch";
                dispatch(Entry(actionData)).then(() => {
                  toast.success("Approved Successfully");
                  setApproved(`data last video approved is ${params.id}`);
                });
              },
            },
            {
              label: "No",
              // onClick: () => alert("Click No")
            },
          ],
        });
      } else if (currentContest?.timeRemainingCurtain === 0) {
        confirmAlert({
          title: "Curtain Closed !!!",
          message: "Do you still want to approve this video ?",
          buttons: [
            {
              label: "Yes",
              onClick: () => {
                const actionData = {};
                actionData.actionUrl = `api/video/approve/${params.id}`;
                actionData.actionMethod = "patch";
                dispatch(Entry(actionData)).then(() => {
                  toast.success("Approved Successfully");
                  setApproved(`data last video approved is ${params.id}`);
                });
              },
            },
            {
              label: "No",
              // onClick: () => alert("Click No")
            },
          ],
        });
        // } else if (params.isIncluded) {
        //   confirmAlert({
        //     title: "",
        //     message: "This video can only be approved for next contest.",
        //     buttons: [
        //       {
        //         label: "Ok",
        //         // onClick: () => alert("Click No")
        //       },
        //     ],
        //   });
      } else {
        confirmAlert({
          title: "",
          message: "Are you sure you want to approve this video?",
          buttons: [
            {
              label: "Yes",
              onClick: () => {
                const actionData = {};
                actionData.actionUrl = `api/video/approve/${params.id}`;
                actionData.actionMethod = "patch";
                dispatch(Entry(actionData)).then(() => {
                  toast.success("Approved Successfully");
                  setApproved(`data last video approved is ${params.id}`);
                });
              },
            },
            {
              label: "No",
              // onClick: () => alert("Click No")
            },
          ],
        });
      }
    } else {
      confirmAlert({
        title: "",
        message: "Are you sure you want to remove this?",
        buttons: [
          {
            label: "Yes",
            onClick: () => {
              const actionData = {};
              actionData.actionUrl = `reported/remove/${params.id}`;
              actionData.actionMethod = "delete";
              dispatch(Entry(actionData)).then(() => {
                toast.success("Removed Successfully");
                setApproved(`data last video approved is ${params.id}`);
              });
            },
          },
          {
            label: "No",
            // onClick: () => alert("Click No")
          },
        ],
      });
    }
  };

  const actionReject = (params) => {
    setPageNumView(pageNum);
    if (section === "video") {
      confirmAlert({
        title: "",
        message: "Are you sure you want to reject this video?",
        buttons: [
          {
            label: "Yes",
            onClick: () => {
              const actionData = {};
              actionData.actionUrl = `api/video/reject/${params.id}`;
              actionData.actionMethod = "patch";
              dispatch(Entry(actionData)).then(() => {
                toast.success("Rejected Successfully");
                setRejected(`data last video rejected is ${params.id}`);
              });
            },
          },
          {
            label: "No",
            // onClick: () => alert("Click No")
          },
        ],
      });
    } else {
      confirmAlert({
        title: "",
        message: "Are you sure you want to ignore this video?",
        buttons: [
          {
            label: "Yes",
            onClick: () => {
              const actionData = {};
              actionData.actionUrl = `reported/reject/${params.id}`;
              actionData.actionMethod = "patch";
              dispatch(Entry(actionData)).then(() => {
                toast.success("Ignored Successfully");
                setRejected(`data last video rejected is ${params.id}`);
              });
            },
          },
          {
            label: "No",
            // onClick: () => alert("Click No")
          },
        ],
      });
    }
  };

  return (
    <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2" id={styles.btnflex}>
      {section === "reported" ? (
        <div id={styles.btnflex}>
          <button
            className={styles[actionbtnStyle]}
            id={styles.actionbtn}
            type="button"
            data-tip="Ignore"
            onClick={() => actionReject(data)}
          >
            {" "}
            <i className="fa fa-ban" />
          </button>
          <button
            className={styles[actionbtnStyle]}
            id={styles.actionbtn2}
            type="button"
            data-tip="Remove"
            onClick={() => actionApprove(data)}
          >
            {" "}
            <i className="fa fa-trash" />
          </button>
        </div>
      ) : (
        <div id={styles.btnflex}>
          <button
            className={styles[actionbtnStyle]}
            id={styles.actionbtn1}
            type="button"
            onClick={() => actionApprove(data)}
          >
            {" "}
            <i className="fa fa-check" />
          </button>
          <button
            className={styles[actionbtnStyle]}
            id={styles.actionbtn2}
            type="button"
            onClick={() => actionReject(data)}
          >
            {" "}
            <i className="fa fa-ban" />
          </button>
        </div>
      )}
      <ReactTooltip className={styles.customTooltip} />
    </div>
  );
}

ApproveReject.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  section: PropTypes.string.isRequired,
  deleteCondition: PropTypes.oneOfType([PropTypes.object]),
  pageNum: PropTypes.number,
};
ApproveReject.defaultProps = {
  deleteCondition: { checkCondition: false, apiUrl: "none" },
  pageNum: 1,
};
export default ApproveReject;
