import React, { useEffect, useState, useContext } from "react";
// import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { Label } from "reactstrap";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/fontawesome-free-solid";
import TextInput from "../../components/TextField/TextField";
// import SelectBox from "../../components/SelectBox/SelectBox";
import { Entry } from "../../redux/entrySlice";
// import DatePickerComponent from "../../components/DatePicker/DatePicker";
import { TableContext } from "../../contexts/tableContext";
import styles from "./createContest.module.scss";
import DateAndTimePickerComponent from "../../components/DateAndTimePicker/DateAndTimePicker";
import NumberInput from "../../components/NumberBox/NumberBox";

function ContestCreate() {
  const { dashboardStyle } = useContext(TableContext);
  const [submiting, setSubmitting] = useState(false);
  const { bodyStyle } = useContext(TableContext);
  const { formthemeStyle } = useContext(TableContext);
  const { setDashboardHeader } = useContext(TableContext);
  const { errormsgStyle } = useContext(TableContext);
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  useEffect(() => {
    window.scrollTo(0, 0);
    setDashboardHeader("Add New Competition");
  }, []);
  const dispatch = useDispatch();
  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  function onSubmitdata(data) {
    console.log("function called", submiting, error);
    setSubmitting(true);
    setError(false);
    if (data.contestName.trim().length === 0) {
      document.getElementById("contestName").focus();
      setError("Please enter contest name");
      setSubmitting(false);
      return;
    }
    if (Number(data.participantCount) <= 0) {
      document.getElementById("participantCount").focus();
      setError("Please enter valid participant count");
      setSubmitting(false);
      return;
    }
    if (Number(data.finalistCount) <= 0) {
      document.getElementById("finalistCount").focus();
      setError("Please enter valid finalist count");
      setSubmitting(false);
      return;
    }
    if (Number(data.participantCount) < Number(data.finalistCount)) {
      document.getElementById("finalistCount").focus();
      setError("Finalist count cannot be greater than participant count");
      setSubmitting(false);
      return;
    }
    console.log("before error check", error);
    if (!error || error === null) {
      const apiData = {
        contestName: data.contestName,
        startDate: data.startDate,
        endDate: data.endDate,
        curtainClose: data.curtainClose,
        participantCount: data.participantCount,
        finalistCount: data.finalistCount,
      };
      data.actionUrl = "contest";
      data.actionMethod = "post";
      data.apiData = apiData;
      dispatch(Entry(data)).then((resp) => {
        setSubmitting(false);
        if (resp.payload.code === 201) {
          navigate(-1);
          toast.success("Contest Created Successfully");
        } else if (resp.payload.code === 406 || resp.payload.code === 400) {
          setError(resp.payload.message);
        } else {
          setError("Something went wrong!");
        }
      });
    } else {
      setSubmitting(false);
    }
  }
  return (
    <div className={styles[bodyStyle]}>
      <div
        className={styles[dashboardStyle]}
        style={{ minHeight: "100vh" }}
        id={styles.dashboardcont2}
      >
        <div className={styles[formthemeStyle]} id={styles.addform}>
          <FontAwesomeIcon
            icon={faArrowLeft}
            value="Back"
            onClick={() => {
              navigate(-1);
            }}
            className={styles.arrowback}
          />
          <div className="container-fluid mt-5">
            <form onSubmit={handleSubmit(onSubmitdata)}>
              <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <TextInput
                    className={styles.inputbox}
                    classNamedark={styles.inputbox1}
                    labelstyle={styles.formlabel}
                    label="Competition Name"
                    fieldName="contestName"
                    placeHolder="Enter Competition Name"
                    register={register}
                    errors={errors}
                    isRequired
                    mandatory
                  />
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <NumberInput
                    className={styles.inputbox}
                    classNamedark={styles.inputbox1}
                    labelstyle={styles.formlabel}
                    label="Number of Participants"
                    fieldName="participantCount"
                    placeHolder="Enter the Number of Participants"
                    register={register}
                    errors={errors}
                    isRequired
                    mandatory
                    maximLength={3}
                    id="participantCount"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12 mb-3">
                  <Label className={styles.formlabel}>Start Date</Label>
                  <span style={{ color: "red", marginLeft: "3px" }}>*</span>
                  <DateAndTimePickerComponent
                    className={styles.dateinputbox}
                    classNamedark={styles.dateinputbox1}
                    control={control}
                    name="startDate"
                    isRequired
                    errors={errors}
                    label="Start Date"
                    minDate={new Date()}
                  />
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 mb-3">
                  <Label className={styles.formlabel}>End Date</Label>
                  <span style={{ color: "red", marginLeft: "3px" }}>*</span>
                  <DateAndTimePickerComponent
                    className={styles.dateinputbox}
                    classNamedark={styles.dateinputbox1}
                    control={control}
                    name="endDate"
                    isRequired
                    errors={errors}
                    label="End Date"
                    minDate={new Date()}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12 mb-3">
                  <Label className={styles.formlabel}>Curtain Close Date</Label>
                  <span style={{ color: "red", marginLeft: "3px" }}>*</span>
                  <DateAndTimePickerComponent
                    className={styles.dateinputbox}
                    classNamedark={styles.dateinputbox1}
                    control={control}
                    name="curtainClose"
                    isRequired
                    errors={errors}
                    label="Curtain Close Date"
                    minDate={new Date()}
                  />
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 mb-3">
                  <NumberInput
                    className={styles.inputbox}
                    classNamedark={styles.inputbox1}
                    labelstyle={styles.formlabel}
                    label="Number of Finalists"
                    fieldName="finalistCount"
                    placeHolder="Enter the Number of Finalists"
                    register={register}
                    errors={errors}
                    isRequired
                    mandatory
                    maximLength={3}
                    id="finalistCount"
                  />
                </div>
              </div>
              <div className="d-flex">
                <input
                  className={styles.formbtn}
                  type="submit"
                  disabled={submiting}
                  value={submiting ? "Please wait.." : "Submit"}
                  onClick={() => {
                    setError(false);
                  }}
                />
                <input
                  className={styles.resetbtn}
                  type="button"
                  value="Reset"
                  onClick={() => {
                    reset();
                  }}
                />
              </div>
              <br />
              {error && <h6 className={styles[errormsgStyle]}>{error}</h6>}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContestCreate;
