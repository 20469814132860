/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
/* eslint-disable global-require */
import axios from "axios";
// import Avatar from 'react-avatar';
import React, { useEffect, useState, useContext } from "react";
import { Col, Row, Container } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { SpinnerDotted } from "spinners-react";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/fontawesome-free-solid";
import { getCookies } from "../../hooks/useCookies";
import { TableContext } from "../../contexts/tableContext";
import styles from "./viewContest.module.scss";
import { DateFormatter } from "../../utilityFunctions/utilsFunctions";
import envValues from "../../enviornment";
import useToggle from "../../hooks/useToggle";
import VideoPopUp from "../../components/VideoViewPopUp/videoView";

function ViewCurrentContest() {
  const { dashboardStyle } = useContext(TableContext);
  const { setDashboardHeader } = useContext(TableContext);
  const { bodyStyle } = useContext(TableContext);
  const { formthemeStyle } = useContext(TableContext);
  const { viewformStyle } = useContext(TableContext);
  const params = useParams();
  let contestId;
  const token = `Bearer ${getCookies("Token")}`;
  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState([]);
  const [showVideoPopUp, setShowVideoPopUp] = useToggle();
  const [timeRemaining, setTimeRemaining] = useState("initial state");
  const dateFormat = getCookies("dateFormat");
  let statusCode;
  const getContestDetails = async () => {
    setLoading(true);
    axios
      .get(`${envValues.REACT_APP_API_ENDPOINT}/contest/${contestId}`, {
        method: "GET",
        headers: { Authorization: token },
      })
      .then((resp) => {
        statusCode = resp.status;
        return resp.data;
      })
      .then((res) => {
        if (statusCode === 200) {
          setDetails(res.data.contest);
          setLoading(false);
          const today = new Date();
          const endDate = new Date(res.data.contest.endDate);
          const initialTimeRemaining = endDate - today;
          if (initialTimeRemaining > 0) {
            setTimeRemaining(initialTimeRemaining);
          }
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        return err;
      });
  };
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
    contestId = params.contestId;
    getContestDetails();
    setDashboardHeader("Competition Details");
  }, []);
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (timeRemaining !== "initial state" && timeRemaining > 0) {
        setTimeRemaining((prevTime) => prevTime - 1000);
      } else if (timeRemaining !== "initial state" && timeRemaining <= 0) {
        navigate("/past-contest");
        clearInterval(intervalId);
      } else {
        clearInterval(intervalId);
      }
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [timeRemaining]);
  const handlePlay = () => {
    setShowVideoPopUp(true);
  };
  const capitalizeFirstLetter = (string) => {
    if (!string) return string;
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  return (
    <div className={styles[bodyStyle]}>
      <div
        className={styles[dashboardStyle]}
        style={{ minHeight: "100vh" }}
        id={styles.dashboardcont2}
      >
        <div className={styles[formthemeStyle]} id={styles.addform}>
          {loading ? (
            <SpinnerDotted
              style={{
                color: "#39979d",
                left: "50%",
                position: "relative",
                textAlign: "center",
                top: "50%",
              }}
            />
          ) : (
            <Container fluid>
              <FontAwesomeIcon
                icon={faArrowLeft}
                value="Back"
                onClick={() => {
                  navigate(-1);
                }}
                className={styles.arrowback}
              />
              <Row className="mt-3">
                <Col lg={12} md={12} sm={12}>
                  <div className={styles.detaildiv}>
                    <Row>
                      <Col lg={6}>
                        <div
                          className={styles[viewformStyle]}
                          id={styles.empdiv}
                        >
                          <span className={styles.title}>Season Number</span>
                          <p className={styles.empname}>
                            {details.contestNumber === undefined || ""
                              ? "N/A"
                              : details?.contestNumber}
                          </p>
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div
                          className={styles[viewformStyle]}
                          id={styles.empdiv}
                        >
                          <span className={styles.title}>Competition Name</span>
                          <p className={styles.empname}>
                            {details.contestName === undefined || ""
                              ? "N/A"
                              : details?.contestName}
                          </p>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6}>
                        <div
                          className={styles[viewformStyle]}
                          id={styles.empdiv}
                        >
                          <span className={styles.title}>Start Date</span>
                          <p className={styles.empname}>
                            {details.startDate
                              ? DateFormatter(
                                  details.startDate,
                                  dateFormat || "toDateTime"
                                )
                              : "N/A"}
                          </p>
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div
                          className={styles[viewformStyle]}
                          id={styles.empdiv}
                        >
                          <span className={styles.title}>End Date</span>
                          <p className={styles.empname}>
                            {details.endDate
                              ? DateFormatter(
                                  details.endDate,
                                  dateFormat || "toDateTime"
                                )
                              : "N/A"}
                          </p>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6}>
                        <div
                          className={styles[viewformStyle]}
                          id={styles.empdiv}
                        >
                          <span className={styles.title}>Curtain Close</span>
                          <p className={styles.empname}>
                            {details.curtainClose
                              ? DateFormatter(
                                  details.curtainClose,
                                  dateFormat || "toDateTime"
                                )
                              : "N/A"}
                          </p>
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div
                          className={styles[viewformStyle]}
                          id={styles.empdiv}
                        >
                          <span className={styles.title}>Duration</span>
                          <p className={styles.empname}>{details?.duration}</p>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6}>
                        <div
                          className={styles[viewformStyle]}
                          id={styles.empdiv}
                        >
                          <span className={styles.title}>
                            Participant Count
                          </span>
                          <p className={styles.empname}>
                            {details?.participantCount
                              ? details.participantCount
                              : "N/A"}
                          </p>
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div
                          className={styles[viewformStyle]}
                          id={styles.empdiv}
                        >
                          <span className={styles.title}>Finalist Count</span>
                          <p className={styles.empname}>
                            {details.finalistCount
                              ? details.finalistCount
                              : "N/A"}
                          </p>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={4}>
                        <div
                          className={`${styles[viewformStyle]} ${styles.case_chck}`}
                          id={styles.empdiv}
                          style={{
                            cursor: "pointer",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                          onClick={() => {
                            navigate(`/finalist/${params?.contestId}`);
                          }}
                        >
                          <div>
                            <span className={styles.title}>
                              Number of Finalists
                            </span>
                            <p className={styles.empname}>
                              {details?.finalists?.length === undefined || ""
                                ? "N/A"
                                : details?.finalists?.length}
                            </p>
                          </div>
                          <FontAwesomeIcon
                            icon={faArrowRight}
                            className={styles.arrowIcon}
                          />
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div
                          className={`${styles[viewformStyle]} ${styles.case_chck}`}
                          id={styles.empdiv}
                          style={{
                            cursor: "pointer",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                          onClick={() => {
                            navigate(`/approved-videos/${params?.contestId}`);
                          }}
                        >
                          <div>
                            <span className={styles.title}>
                              Number of Videos Uploaded
                            </span>
                            <p className={styles.empname}>
                              {details.videoCount === undefined || ""
                                ? "0"
                                : details?.videoCount}
                            </p>
                          </div>
                          <FontAwesomeIcon
                            icon={faArrowRight}
                            className={styles.arrowIcon}
                          />
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div
                          className={styles[viewformStyle]}
                          id={styles.empdiv}
                        >
                          <span className={styles.title}>Status</span>
                          <p className={styles.empname}>
                            {capitalizeFirstLetter(details.status)}
                          </p>
                        </div>
                      </Col>
                    </Row>
                    {(details?.status === "ended" ||
                      details?.winnerVideo !== undefined) && (
                      <Row>
                        <Col lg={6}>
                          <div
                            className={styles[viewformStyle]}
                            id={styles.empdiv}
                          >
                            <span className={styles.title}>Winner</span>
                            <p className={styles.empname}>
                              {details?.winnerUser?.name === undefined || ""
                                ? "N/A"
                                : details?.winnerUser?.name}
                            </p>
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div
                            className={styles[viewformStyle]}
                            id={styles.empdiv}
                            style={{ cursor: "pointer" }}
                            onClick={() => handlePlay()}
                          >
                            <span className={styles.title}>Winner Video</span>
                            <p className={styles.empname}>
                              {details?.winnerVideo?.description ===
                                undefined || ""
                                ? "N/A"
                                : details?.winnerVideo?.description}
                            </p>
                          </div>
                        </Col>
                      </Row>
                    )}
                    {details.isCurtainClosed &&
                      details?.winnerVideo === undefined && (
                        <Row>
                          <Col lg={6}>
                            <input
                              className={styles.formbtn}
                              type="submit"
                              value="Update Winner"
                              onClick={() =>
                                navigate(`/finalist-videos/${details?.id}`)
                              }
                            />
                          </Col>
                        </Row>
                      )}
                  </div>
                </Col>
              </Row>
            </Container>
          )}
        </div>
      </div>
      {showVideoPopUp ? (
        <VideoPopUp
          dataId={details?.winnerVideo?.id}
          handleClose={setShowVideoPopUp}
        />
      ) : null}
    </div>
  );
}

export default ViewCurrentContest;
